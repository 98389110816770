<template>
  <div class="outer">
    <div class="hl-content">
      <div class="back">
        <!-- <el-button type="primary" @click="goBack" class="addIdType1"
          >客房控制</el-button
        > -->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/roomControl' }"
            >客房控制</el-breadcrumb-item
          >
          <el-breadcrumb-item class="sbkz">设备控制</el-breadcrumb-item>
          <span class="sbkz1">({{ infor.roomNo }})</span>
        </el-breadcrumb>

        <!-- <el-breadcrumb-item @click="goBack">客房控制</el-breadcrumb-item> -->
        <!-- <el-button type="primary"  @click="goBack" icon="el-icon-arrow-left" class="addIdType1">客房控制</el-button> -->
      </div>

      <div class="container">
        <!-- 左侧 -->
        <div class="left">
          <div class="left-top">
            <div
              v-for="(item, index) in electricList"
              :key="index"
              :class="['electric', { chooseElect: index == electricIndex }]"
              @click="chooseElectric(index, item.typeName, item)"
            >
              <div class="electricTitle">
                <div v-if="item.typeName == '双色温灯'">
                  <div v-if="electricName == '双色温灯'">
                    <img class="electricImg" src="../../img/ic_dg2.png" alt />
                  </div>
                  <div v-else>
                    <img class="electricImg" src="../../img/ic_dg.png" alt />
                  </div>
                </div>
                <div v-if="item.typeName == '彩灯'">
                  <div v-if="electricName == '彩灯'">
                    <img class="electricImg" src="../../img/ic_dg2.png" alt />
                  </div>
                  <div v-else>
                    <img class="electricImg" src="../../img/ic_dg.png" alt />
                  </div>
                </div>
                <div v-if="item.typeName == '调光灯'">
                  <div v-if="electricName == '调光灯'">
                    <img class="electricImg" src="../../img/ic_dg2.png" alt />
                  </div>
                  <div v-else>
                    <img class="electricImg" src="../../img/ic_dg.png" alt />
                  </div>
                </div>
                <div v-if="item.typeName == '空调'">
                  <div v-if="electricName == '空调'">
                    <img class="electricImg" src="../../img/ic_kt2.png" alt />
                  </div>
                  <div v-else>
                    <img class="electricImg" src="../../img/ic_kt.png" alt />
                  </div>
                </div>

                <div v-if="item.typeName == '电视'">
                  <div v-if="electricName == '电视'">
                    <img class="electricImg" src="../../img/ic_tv2.png" alt />
                  </div>
                  <div v-else>
                    <img class="electricImg" src="../../img/ic_tv.png" alt />
                  </div>
                </div>

                <div v-if="item.typeName == '窗帘'">
                  <div v-if="electricName == '窗帘'">
                    <img class="electricImg" src="../../img/ic_cl2.png" alt />
                  </div>
                  <div v-else>
                    <img class="electricImg" src="../../img/ic_cl.png" alt />
                  </div>
                </div>
                <div v-if="item.typeName == '门锁'">
                  <div v-if="electricName == '门锁'">
                    <img
                      class="electricImg"
                      src="../../img/ic_mensuo2.png"
                      alt
                    />
                  </div>
                  <div v-else>
                    <img
                      class="electricImg"
                      src="../../img/ic_mensuo.png"
                      alt
                    />
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'electricName',
                  { chooseItem1: index == electricIndex },
                ]"
              >
                {{ item.typeName }}
              </div>
            </div>
          </div>
          <div class="left-bottom" v-if="fjsbkz">
            <!-- 门锁 -->
            <div class="light" v-if="electricName == '门锁'">
              <div class="msTop">
                <div class="dgsz">
                  <img src="../../img/ic_mssz.png" alt />
                  <span class="lightSet">门锁设置</span>
                </div>
              </div>

              <div class="msCenter">
                <img src="../../img/msCenter.png" @click="turnOnLock" alt />
              </div>
              <div class="mensuoOut">
                <div class="mensuoInfor">
                  <div
                    v-for="(item, index) in mensuoInfor"
                    :key="index"
                    class="mensuo"
                  >
                    <div
                      class="yuan1"
                      v-if="item.paramName == '电池低电压报警'"
                    ></div>
                    <div
                      class="yuan2"
                      v-if="item.paramName == '电池电压'"
                    ></div>
                    <div
                      class="yuan3"
                      v-if="item.paramName == '反锁舌状态'"
                    ></div>
                    <div>{{ item.paramName }}:</div>
                    <div class="res">{{ item.res }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 调光灯 -->
            <div class="light" v-if="electricName == '调光灯'">
              <div class="light-top">
                <div class="dgsz">
                  <img src="../../img/ic_dgsz.png" alt />
                  <span class="lightSet">灯光设置</span>
                </div>
              </div>
              <div class="centerBox">
                <div v-for="(item, index) in tgdList" :key="index">
                  <div class="dgBox">
                    <div class="dengGuang">
                      <div>
                        <img src="../../img/img1.png" alt class="img" />
                      </div>
                      <div class="dengGuangInfor">
                        <div class="name">{{ item.deviceName }}</div>
                        <el-switch
                          v-model="item.Status"
                          :disabled="flag != 0"
                          active-value="TurnOn"
                          inactive-value="TurnOff"
                          active-color="#1890FF"
                          @change="cdSwitchChange(item, index)"
                          inactive-color="#9BA4B0"
                        >
                        </el-switch>
                      </div>
                    </div>
                    <div class="ld">
                      <span style="margin-right: 10px">亮度：</span>
                      <div style="width: 150px" class="showList">
                        <div
                          style="
                            width: 200px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <input
                            id="range"
                            type="range"
                            v-model="item.SetBrightness"
                            @change="changeLight(item, index)"
                            name="points"
                            min="0"
                            max="100"
                          />
                          <span id="value" v-html="item.SetBrightness"> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 彩灯 -->
            <div class="light" v-if="electricName == '彩灯'">
              <div class="light-top">
                <div class="dgsz">
                  <img src="../../img/ic_dgsz.png" alt />
                  <span class="lightSet">灯光设置</span>
                </div>
              </div>
              <div class="centerBox">
                <div v-for="(item, index) in colorforList" :key="index">
                  <div class="dgBox1">
                    <div class="dengGuang">
                      <div>
                        <img src="../../img/img1.png" alt class="img" />
                      </div>
                      <div class="dengGuangInfor">
                        <div class="name">{{ item.deviceName }}</div>
                        <el-switch
                          v-model="item.Status"
                          :disabled="flag != 0"
                          active-value="TurnOn"
                          inactive-value="TurnOff"
                          active-color="#1890FF"
                          @change="cdChange(item, index)"
                          inactive-color="#9BA4B0"
                        >
                        </el-switch>
                      </div>
                    </div>
                    <div class="ld">
                      <span style="margin-right: 10px">亮度：</span>
                      <div style="width: 150px" class="showList">
                        <div
                          style="
                            width: 200px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <input
                            id="range3"
                            type="range"
                            v-model="item.SetBrightness"
                            @change="cdLightChange(item, index)"
                            name="points"
                            min="0"
                            max="100"
                          />
                          <span id="value" v-html="item.SetBrightness"> </span>
                        </div>
                        <!-- <img
                          src="../../img/btn_reduce.png"
                          @click="cdLightChange(item, 'reduce')"
                          v-if="item.SetBrightness > 0"
                          alt
                        />
                        <img
                          src="../../img/btn_reduce.png"
                          @click="chaochu"
                          v-else
                          alt
                        />
                        <div>{{ item.SetBrightness }}%</div>
                        <img
                          src="../../img/btn_add.png"
                          @click="cdLightChange(item, 'add')"
                          v-if="item.SetBrightness < 100"
                          alt
                        />
                        <img
                          src="../../img/btn_add.png"
                          v-else
                          @click="chaochu"
                          alt
                        /> -->
                      </div>
                    </div>
                    <div class="ld">
                      <span style="margin-right: 10px">颜色：</span>
                      <div style="width: 200px">
                        <colorPicker
                          v-model="item.SetColor"
                          @change="sendColor(item)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 空调 -->
            <div class="light" v-if="electricName == '空调'">
              <div class="light-top">
                <div class="dgsz">
                  <img src="../../img/ic_dgsz.png" alt />
                  <span class="lightSet">空调设置</span>
                </div>
              </div>
              <div class="centerBox">
                <div v-for="(item, index) in ktList" :key="index">
                  <div class="dgBox2">
                    <div class="dengGuang">
                      <div
                        style="
                          background-color: #fff;
                          padding: 20px;
                          border-radius: 15px;
                        "
                      >
                        <img
                          src="../../img/ic_kt.png"
                          style="height: 40px; width: 40px"
                          alt
                        />
                      </div>
                      <div class="dengGuangInfor">
                        <div class="name">当前温度：{{ item.RoomTemp }}℃</div>
                        <div class="name">{{ item.deviceName }}</div>
                        <el-switch
                          v-model="item.Status"
                          :disabled="flag != 0"
                          active-value="TurnOn"
                          inactive-value="TurnOff"
                          active-color="#1890FF"
                          @change="ktSwitchChange(item, index)"
                          inactive-color="#9BA4B0"
                        >
                        </el-switch>
                      </div>
                    </div>
                    <div class="ld1">
                      <span style="margin-right: 10px">温度：</span>
                      <div style="width: 150px" class="showList">
                        <img
                          src="../../img/btn_reduce.png"
                          @click="changeKtwd(item, 'reduce')"
                          v-if="item.SetTemp > 16"
                          alt
                        />
                        <img
                          src="../../img/btn_reduce.png"
                          @click="ktchaochu"
                          v-else
                          alt
                        />
                        <div>{{ item.SetTemp }}℃</div>
                        <img
                          src="../../img/btn_add.png"
                          @click="changeKtwd(item, 'add')"
                          v-if="item.SetTemp < 30"
                          alt
                        />
                        <img
                          src="../../img/btn_add.png"
                          v-else
                          @click="chaochu"
                          alt
                        />
                      </div>
                    </div>
                    <div class="ld1">
                      <span style="margin-right: 10px">风速：</span>
                      <div class="showList">
                        <el-radio-group v-model="item.SetFan">
                          <el-radio
                            v-for="items in fengsuList"
                            :key="items.valueCodes"
                            :label="items.valueCode"
                            @change="SetFanChange(item, items.valueCode, index)"
                          >
                            {{ items.valueDesc }}
                          </el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="ld1">
                      <span style="margin-right: 10px">模式：</span>
                      <div class="showList">
                        <el-radio-group v-model="item.SetMode">
                          <el-radio
                            v-for="items in SetModeList"
                            :key="items.valueCodes"
                            :label="items.valueCode"
                            @change="SetModeChange(item, items.valueCode)"
                          >
                            {{ items.valueDesc }}
                          </el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 电视 -->
            <div class="light" v-if="electricName == '电视'">
              <div class="light-top">
                <div class="dgsz">
                  <img src="../../img/ic_tvsz.png" alt />
                  <span class="lightSet">电视设置</span>
                </div>
                <!-- <div class="twoColor">频道一</div> -->
                <!-- <div class="twoColor">
                  <div>{{ tvSet }}</div>
                </div> -->
                <div class="ONOff">
                  <span class="ON">ON</span>
                  <el-switch
                    v-model="tvStatus"
                    :disabled="flag != 0"
                    active-value="TurnOn"
                    inactive-value="TurnOff"
                    active-color="#1890FF"
                    inactive-color="#9BA4B0"
                    @change="tvSwitch"
                  >
                  </el-switch>
                </div>
              </div>
              <div class="tv-center">
                <!-- <img src="../../img/button_jian.png" alt /> -->
                <div class="tempe1">
                  <div class="toleft" @click="toleft"></div>
                  <div
                    class="toright"
                    @click="toright"
                    v-if="tvSet < 990"
                  ></div>

                  <div class="totop" @click="totop"></div>
                  <div class="tobottom" @click="tobottom"></div>
                  <img src="../../img/pindao.png" alt />
                </div>
                <!-- <img src="../../img/button_jia.png" alt /> -->
              </div>
              <div class="light-bottom">
                <div class="window">
                  <img
                    src="../../img/jinyin.png"
                    class="jinyinon"
                    alt
                    @click="jinyin"
                  />
                  <div class="shengyin">
                    <img src="../../img/yinliang.png" alt class="yinliang" />
                    <div
                      :class="[
                        'showsy',
                        { sy: sy == 0 },
                        { sy1: sy == 1 },
                        { sy2: sy == 2 },
                        { sy3: sy == 3 },
                        { sy4: sy == 4 },
                        { sy5: sy == 5 },
                        { sy6: sy == 6 },
                        { sy7: sy == 7 },
                        { sy8: sy == 8 },
                        { sy9: sy == 9 },
                        { sy10: sy == 10 },
                      ]"
                    >
                      <img src="../../img/yinliang2.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- 频道 -->
              <div class="tvChoose">
                <div class="tvChoosebox">
                  <div
                    v-for="(item, index) in TvChannelChoose"
                    :key="index"
                    :class="[{ jiudian: index == chooseChannel }]"
                    @click="changeTvChannel(index, item.channelNo)"
                  >
                    <!-- {{item.channelNo}} -->
                    {{ item.channelName }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 窗帘 -->
            <div class="light" v-if="electricName == '窗帘'">
              <div class="light-top">
                <div class="dgsz">
                  <img src="../../img/ic_clsz.png" alt />
                  <span class="lightSet">窗帘设置</span>
                </div>
              </div>
              <div class="centerBox">
                <div v-for="(item, index) in CLmode" :key="index">
                  <div class="dgBoxCl">
                    <div class="dengGuang">
                      <div>
                        <img src="../../img/img2.png" alt class="img" />
                      </div>
                      <div class="dengGuangInfor">
                        <div class="name">{{ item.deviceName }}</div>
                        <el-switch
                          v-model="item.Status"
                          :disabled="flag != 0"
                          active-value="TurnOn"
                          inactive-value="TurnOff"
                          active-color="#1890FF"
                          @change="clChange(item, index)"
                          inactive-color="#9BA4B0"
                        >
                        </el-switch>
                      </div>
                    </div>
                    <div class="ld">
                      <span style="margin-right: 10px; width: 70px"
                        >开合值：</span
                      >
                      <div
                        style="width: 200px; display: flex; align-items: center"
                      >
                        <input
                          id="range4"
                          type="range"
                          v-model="item.SetValue"
                          @change="windowOpeanChange(item, index)"
                          name="points"
                          min="0"
                          max="100"
                        />
                        <span id="value" v-html="item.SetValue"> </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="tv-center">
                <div class="tempe">
                  <img src="../../img/chuanglian.png" alt />
                </div>
              </div>
              <div class="light-bottom">
                <div class="window">
                  <span class="khd">开合度</span>
                  <div class="slider">
                    <el-slider
                      v-model="windowOpean"
                      :disabled="flag != 0"
                      @change="windowOpeanChange(CLSet)"
                    ></el-slider>
                  </div>
                </div>
              </div> -->
              <!-- 窗帘 -->
              <div></div>
            </div>

            <!-- 双色温灯 -->
            <div class="light" v-if="electricName == '双色温灯'">
              <div class="light-top">
                <div class="dgsz">
                  <img src="../../img/ic_dgsz.png" alt />
                  <span class="lightSet">灯光设置</span>
                </div>
              </div>
              <div class="centerBox">
                <div v-for="(item, index) in sswdList" :key="index">
                  <div class="dgBox1">
                    <div class="dengGuang">
                      <div>
                        <img src="../../img/img1.png" alt class="img" />
                      </div>
                      <div class="dengGuangInfor">
                        <div class="name">{{ item.deviceName }}</div>
                        <el-switch
                          v-model="item.Status"
                          :disabled="flag != 0"
                          active-value="TurnOn"
                          inactive-value="TurnOff"
                          active-color="#1890FF"
                          @change="sswdSwitchChange(item, index)"
                          inactive-color="#9BA4B0"
                        >
                        </el-switch>
                      </div>
                    </div>
                    <div class="ld">
                      <span style="margin-right: 10px">亮度：</span>
                      <div style="width: 150px" class="showList">
                        <div
                          style="
                            width: 200px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <input
                            id="range1"
                            type="range"
                            v-model="item.SetBrightness"
                            @change="changesswd(item, index)"
                            name="points"
                            min="0"
                            max="100"
                          />
                          <span id="value" v-html="item.SetBrightness"> </span>
                        </div>
                        <!-- <img
                          src="../../img/btn_reduce.png"
                          @click="changesswd(item, 'reduce')"
                          v-if="item.SetBrightness > 0"
                          alt
                        />
                        <img
                          src="../../img/btn_reduce.png"
                          @click="chaochu"
                          v-else
                          alt
                        />
                        <div>{{ item.SetBrightness }}%</div>
                        <img
                          src="../../img/btn_add.png"
                          @click="changesswd(item, 'add')"
                          v-if="item.SetBrightness < 100"
                          alt
                        />
                        <img
                          src="../../img/btn_add.png"
                          v-else
                          @click="chaochu"
                          alt
                        /> -->
                      </div>
                    </div>
                    <div class="ld">
                      <span style="margin-right: 10px">色温：</span>
                      <div style="width: 150px" class="showList">
                        <div
                          style="
                            width: 200px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <input
                            id="range2"
                            type="range"
                            v-model="item.SetColorTemp"
                            @change="changesswdsw(item, index)"
                            name="points"
                            min="2700"
                            max="7000"
                          />
                          <span id="value" v-html="item.SetColorTemp"> </span>
                        </div>
                        <!-- <img
                          src="../../img/btn_reduce.png"
                          @click="changesswdsw(item, 'reduce')"
                          alt
                        />

                        <div>{{ item.SetColorTemp }}</div>
                        <img
                          src="../../img/btn_add.png"
                          @click="changesswdsw(item, 'add')"
                          alt
                        /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="right" v-if="fjsbkz">
          <div class="waterAndElect">
            <div
              :class="[
                { water: WaterMeter.Status == '正常' },
                { elect: WaterMeter.Status == '故障' },
              ]"
              v-if="WaterMeter.Status == '正常'"
            >
              <div class="waterInfor">
                <div>
                  <div class="shuibiao">水表</div>
                  <div class="shuibiaoNumber">{{ WaterMeter.SetValue }}</div>
                </div>
                <div>
                  <img src="../../img/water.png" alt />
                </div>
              </div>
              <div
                :class="[
                  { waterBottom: WaterMeter.Status == '正常' },
                  { electBottom: WaterMeter.Status == '故障' },
                ]"
              >
                <div
                  :class="[
                    { waterRidus: WaterMeter.Status == '正常' },
                    { electRidus: WaterMeter.Status == '故障' },
                  ]"
                ></div>
                <div>{{ WaterMeter.Status }}</div>
              </div>
            </div>
            <div
              :class="[
                { water: ElectricityMeter.Status == '正常' },
                { elect: ElectricityMeter.Status == '故障' },
              ]"
              v-if="ElectricityMeter.Status == '正常'"
            >
              <div class="electInfor">
                <div>
                  <div class="shuibiao">电表</div>
                  <div class="shuibiaoNumber">
                    {{ ElectricityMeter.SetValue }}
                  </div>
                </div>
                <div>
                  <img src="../../img/elect.png" alt />
                </div>
              </div>
              <div
                :class="[
                  { waterBottom: ElectricityMeter.Status == '正常' },
                  { electBottom: ElectricityMeter.Status == '故障' },
                ]"
              >
                <div
                  class=""
                  :class="[
                    { waterRidus: ElectricityMeter.Status == '正常' },
                    { electRidus: ElectricityMeter.Status == '故障' },
                  ]"
                ></div>
                <div>{{ ElectricityMeter.Status }}</div>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in switchList" :key="index">
            <!-- <div>{{ item.typeName }}</div> -->
            <div v-for="(item2, index2) in item.roomDeviceList" :key="index2">
              <div
                v-for="(item3, index3) in item2.roomDeviceStatusList"
                :key="index3"
                @click="changeSwitch(index, item3.realValue)"
              >
                <div class="switch">
                  {{ item2.deviceName }}
                  {{ item3.val }}
                  <div>
                    <el-switch
                      v-model="item3.realValue"
                      active-color="#1890FF"
                      inactive-color="#D1D1D1"
                      active-value="TurnOn"
                      inactive-value="TurnOff"
                      :disabled="item2.deviceName == '紧急呼叫'"
                      @change="
                        switchChange(item2.id, item3, index, index2, index3)
                      "
                    >
                    </el-switch>
                    <!-- <img
                      v-if="item3.realValue == 'TurnOff'"
                      src="../../img/OnButton.png"
                      alt
                    />
                    <img v-else src="../../img/offButton.png" alt /> -->
                  </div>
                </div>
              </div>
              <!-- -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <addModify ref="addModify" />
  </div>
</template>
<script>
import { getRequest, postRequest } from "@/api/api.js";
import addModify from "@/views/roomControl/addModify.vue";
export default {
  data() {
    return {
      chooseChannel: null,
      infor: {},
      windowStatus: true,
      tvStatus: true,
      lightStatus: true,
      cdlightStatus: true,
      ktStatus: true,
      changelightStatus: true,
      electricIndex: 0,
      electricName: "调光灯",
      windowOpean: null,
      aaa: null,
      electricList: [],
      switchList: [],
      lightSet: null,
      EquInfor: [],
      ktSet: null,
      // 空调温度
      tempe: null,
      tempeWindow: null,
      // 温控灯亮度
      lightNum: null,
      changelightNum: null,
      colorLightNum: null,
      volume: "11",
      SelectColor: null,
      Color: null,
      WaterMeter: {},
      ElectricityMeter: {},
      sy: 5,
      tgdList: [],
      tgdSet: null,
      flag: 0,
      CLmode: [],
      sswdList: [],
      CLSet: null,
      ClLnfor: [],
      color: "#ff0000",
      TvChannelChoose: [],
      mensuoInfor: {},
    };
  },
  components: {
    addModify,
    // disableModify
  },
  created() {
    if (!this.power.checkedpower("0501002")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.fjsbkz = this.power.checkedpower("0501003");
    this.infor = this.$route.query;
    console.log(1111, this.infor.roomNo);
    this.getSelectAllDevices();
    this.getFounderList();
  },
  methods: {
    getlist() {
      let parms = {
        roomId: Number(this.infor.id),
      };
      postRequest("selectAllDevices", parms).then((res) => {
        if (res.status == 200) {
          this.electricList = [];
          res.data.forEach((item) => {
            if (
              item.typeCode == "BiColourLight" ||
              item.typeCode == "AIR" ||
              item.typeCode == "TV" ||
              item.typeCode == "Curtain" ||
              item.typeCode == "CurtainsSheer" ||
              item.typeCode == "Dimming" ||
              item.typeCode == "Colorful" ||
              (item.typeCode == "DoorLock" && item.roomDeviceList.length > 0)
            ) {
              this.electricList.push(item);
              // this.EquInfor = this.electricList[0];
            }
          });
          //  this.electricList
        }
      });
    },
    getSelectAllDevices() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        roomId: Number(this.infor.id),
      };
      postRequest("selectAllDevices", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.switchList = [];
          this.electricList = [];
          res.data.forEach((item) => {
            if (
              item.typeCode == "Light" ||
              item.typeCode == "Socket" ||
              item.typeCode == "ExhaustFan" ||
              item.typeCode == "SOSService" ||
              item.typeCode == "ClearService" ||
              item.typeCode == "DNDService" ||
              item.typeCode == "CheckOutService" ||
              item.typeCode == "Switch" ||
              item.typeCode == "SwitchLED" ||
              item.typeCode == "DoorSensor" ||
              item.typeCode == "InfraredSensor" ||
              item.typeCode == "Scene" ||
              item.typeCode == "Valve" ||
              item.typeCode == "Card"
            ) {
              // let list = [];
              // item.roomDeviceList.forEach((roomItem) => {
              //   roomItem.roomDeviceStatusList.forEach((roomItem2) => {
              //     console.log(23, roomItem2);
              //     if (roomItem2.realValue == "TurnOn") {
              //       roomItem2.realValue = true;
              //     } else {
              //       roomItem2.realValue = false;
              //     }
              //   });
              // });
              this.switchList.push(item);
              // this.switchList = list;
            }
            if (
              item.typeCode == "BiColourLight" ||
              item.typeCode == "AIR" ||
              item.typeCode == "TV" ||
              item.typeCode == "Curtain" ||
              item.typeCode == "CurtainsSheer" ||
              item.typeCode == "Dimming" ||
              item.typeCode == "Colorful" ||
              (item.typeCode == "DoorLock" && item.roomDeviceList.length > 0)
            ) {
              this.electricList.push(item);
              this.EquInfor = this.electricList[0];
            }
            // 水表电表
            if (item.typeCode == "WaterMeter") {
              if (item.roomDeviceList.length == 0) {
                return;
              }
              item.roomDeviceList[0].roomDeviceStatusList.forEach((item1) => {
                if (item1.paramCode == "SetValue") {
                  this.WaterMeter.SetValue = item1.realValue;
                }
                if (item1.paramCode == "Status") {
                  if (item1.realValue == "Normal") {
                    this.WaterMeter.Status = "正常";
                  } else {
                    this.WaterMeter.Status = "故障";
                  }
                }
              });
            }
            if (item.typeCode == "ElectricityMeter") {
              if (item.roomDeviceList.length == 0) {
                return;
              }
              item.roomDeviceList[0].roomDeviceStatusList.forEach((item1) => {
                console.log(item1);
                if (item1.paramCode == "SetValue") {
                  this.ElectricityMeter.SetValue = item1.realValue;
                }
                if (item1.paramCode == "Status") {
                  if (item1.realValue == "Normal") {
                    this.ElectricityMeter.Status = "正常";
                  } else {
                    this.ElectricityMeter.Status = "故障";
                  }
                }
              });
            }
          });

          let list = this.electricList[0].roomDeviceList[0];
          this.tgdList = this.electricList[0].roomDeviceList;
          this.tgdList.forEach((item) => {
            item.roomDeviceStatusList.forEach((item2) => {
              if (item2.paramCode == "Status") {
                item.Status = item2.realValue;
              }
              if (item2.paramCode == "SetBrightness") {
                item.SetBrightness = Number(item2.realValue);
              }
            });
          });
          this.tgdSet = list.id;
          list.roomDeviceStatusList.forEach((item) => {
            if (item.paramCode == "Status") {
              if (item.realValue == "TurnOff") {
                this.changelightStatus = false;
              } else {
                this.changelightStatus = true;
              }
            }
            if (item.paramCode == "SetBrightness") {
              this.changelightNum = item.realValue;
              this.changelightName = "SetBrightness";
            }
          });
          return;
          this.list1 = [];
          this.list2 = [];

          this.devicesInfor = res.data;
          this.devicesInfor.forEach((item) => {
            if (
              item.deviceTypeCode == "SOS" ||
              item.deviceTypeCode == "Clear" ||
              item.deviceTypeCode == "DND"
            ) {
              this.list1.push(item);
            } else {
              if (item.deviceTypeCode == "AIR") {
                this.AIR = true;
                this.air.deviceName = item.deviceName;
                item.roomDeviceStatusList.forEach((item1) => {
                  console.log(11, item1);
                  if (item1.paramCode == "onoff") {
                    this.air.onoff = item1;
                  }
                  if (item1.paramCode == "SetFan") {
                    this.air.SetFan = item1;
                  }
                  if (item1.paramCode == "SetMode") {
                    this.air.SetMode = item1;
                  }
                  if (item1.paramCode == "SetTemp") {
                    this.air.SetTemp = item1;
                  }
                });
              }
            }
          });
          console.log(1212, res.data);
          console.log(1212, this.list1);
          // console.log(1212, this.list2);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
            duration: 3000,
          });
        }
      });
    },
    Choosepindao(index) {
      this.chooseChannel = index;
    },
    //  选择
    chooseElectric(index, e, item) {
      this.returnInfor = item;
      console.log(item);
      this.getlist();
      this.electricIndex = index;
      this.electricName = e;
      this.EquInfor = item;
      if (e == "双色温灯") {
        this.sswdList = item.roomDeviceList;
        this.sswdList.forEach((item) => {
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "Status") {
              item.Status = item2.realValue;
            }
            if (item2.paramCode == "SetBrightness") {
              item.SetBrightness = Number(item2.realValue);
            }
            if (item2.paramCode == "SetColorTemp") {
              item.SetColorTemp = Number(item2.realValue);
            }
          });
        });
      }

      if (e == "空调") {
        this.ktList = item.roomDeviceList;
        this.ktList.forEach((item) => {
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "Status") {
              item.Status = item2.realValue;
            }
            if (item2.paramCode == "RoomTemp") {
              item.RoomTemp = Number(item2.realValue);
            }
            if (item2.paramCode == "SetFan") {
              item.SetFan = item2.realValue;
              this.fengsuList = item2.values;
            }
            if (item2.paramCode == "SetMode") {
              item.SetMode = item2.realValue;
              this.SetModeList = item2.values;
            }
            if (item2.paramCode == "SetTemp") {
              item.SetTemp = Number(item2.realValue);
            }
          });
        });
      }
      if (e == "窗帘") {
        this.CLmode = item.roomDeviceList;
        this.CLmode.forEach((item) => {
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "Status") {
              item.Status = item2.realValue;
            }
            if (item2.paramCode == "SetValue") {
              item.SetValue = Number(item2.realValue);
            }
          });
        });
      }
      if (e == "电视") {
        item.roomDeviceList[0].roomDeviceStatusList.forEach((item) => {
          if (item.paramCode == "SetChannel") {
            item.values.forEach((item, index) => {
              item.val = index;
            });
            this.tvList = item.values;
            this.tvSet = item.values[0].valueCode;
          }
          this.tvStatus = item.realValue;
          // if (item.paramCode == "Status") {
          //   if (item.realValue == "TurnOff") {
          //     this.tvStatus = false;
          //   } else {
          //     this.tvStatus = true;
          //   }
          // }
          if (item.paramCode == "SetVolume") {
            this.volume = item.realValue;
          }
          if (item.paramCode == "SetChannel") {
            console.log(22, this.TvChannelChoose);
            this.TvChannelChoose.forEach((item2, index2) => {
              if (item2.channelNo == Number(item.realValue)) {
                this.returnChannel = index2;
                this.chooseChannel = index2;
              } else {
                this.chooseChannel = null;
              }
            });
          }
        });
      }

      if (e == "调光灯") {
        this.tgdList = item.roomDeviceList;
        this.tgdList.forEach((item) => {
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "Status") {
              item.Status = item2.realValue;
            }
            if (item2.paramCode == "SetBrightness") {
              item.SetBrightness = Number(item2.realValue);
            }
          });
        });
      }

      if (e == "彩灯") {
        this.colorforList = item.roomDeviceList;
        this.colorforList.forEach((item) => {
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "Status") {
              item.Status = item2.realValue;
            }
            if (item2.paramCode == "SetBrightness") {
              item.SetBrightness = Number(item2.realValue);
            }
            if (item2.paramCode == "SetColor") {
              item.SetColor = item2.realValue;
            }
          });
        });
      }
      if (e == "门锁") {
        if (item.roomDeviceList[0]) {
          let list = item.roomDeviceList[0].roomDeviceStatusList;
          console.log(list);
          list.forEach((item1) => {
            if (item1.values.length > 1) {
              console.log(item1);
              if (item1.realValue == "TurnOff") {
                item1.res = "关闭";
              } else {
                item1.res = "开启";
              }
            } else {
              item1.res = item1.realValue;
            }
          });
          console.log(4444, list);
          this.mensuoInfor = list;
          this.mensuoId = item.roomDeviceList[0].id;
        }
      }
    },
    // 调光灯下拉选择
    changetgd(value) {
      this.tgdList.forEach((item) => {
        if (value == item.id) {
          item.roomDeviceStatusList.forEach((item) => {
            console.log(5566, item);
            if (item.paramCode == "SetBrightness") {
              this.changelightNum = item.realValue;
            }
            if (item.paramCode == "Status") {
              if (item.realValue == "TurnOff") {
                this.changelightStatus = false;
              } else {
                this.changelightStatus = true;
              }
            }
          });
        }
      });
    },
    // 双色温下拉选择
    changelightSet(value) {
      this.lightLnfor.forEach((item) => {
        if (value == item.id) {
          console.log(2, item);
          item.roomDeviceStatusList.forEach((item) => {
            if (item.paramCode == "SetBrightness") {
              this.lightNum = item.realValue;
            }
            if (item.paramCode == "SetColorTemp") {
              this.SelectColor = item.realValue;
            }
            if (item.paramCode == "Status") {
              if (item.realValue == "TurnOff") {
                this.lightStatus = false;
              } else {
                this.lightStatus = true;
              }
            }
          });
        }
      });
    },
    lightNumReduce(id) {
      if (this.changelightNum > 0) {
        // this.changelightNum--;
        let item = "reduce";
        this.changeLight(id, item);
      }
    },
    lightNumAdd(id) {
      if (this.changelightNum < 100) {
        let item = "add";
        // this.changelightNum++;
        this.changeLight(id, item);
      }
    },
    // 彩灯
    colorLightReduce(id) {
      if (this.colorLightNum > 0) {
        // this.colorLightNum--;
        let item = "reduce";
        this.cdLightChange(id, item);
      }
    },
    colorLightAdd(id) {
      if (this.lightNum < 100) {
        // this.colorLightNum++;
        let item = "add";
        // this.changelightNum++;
        this.cdLightChange(id, item);
      }
    },
    // 空调温度加减
    radius() {
      if (this.tempe > 16) {
        // this.tempe--;
        let item = "add";
        this.ktChangeTemper(item);
      }
    },
    add() {
      if (this.tempe < 30) {
        let item = "add";
        // this.tempe++;
        this.ktChangeTemper(item);
      }
    },
    changeCLSet(value) {
      this.ClLnfor.forEach((item) => {
        if (value == item.id) {
          console.log(item);
          item.roomDeviceStatusList.forEach((item) => {
            if (item.paramCode == "SetValue") {
              this.windowOpean = Number(item.realValue);
            }
            if (item.paramCode == "Status") {
              if (item.realValue == "TurnOff") {
                this.windowStatus = false;
              } else {
                this.windowStatus = true;
              }
            }
          });
        }
      });
    },
    changeColorLight(value) {
      this.colorlightLnfor.forEach((item) => {
        if (value == item.id) {
          item.roomDeviceStatusList.forEach((item) => {
            if (item.paramCode == "SetBrightness") {
              this.colorLightNum = item.realValue;
            }
            if (item.paramCode == "Status") {
              if (item.realValue == "TurnOff") {
                this.cdlightStatus = false;
              } else {
                this.cdlightStatus = true;
              }
            }
          });
        }
      });
    },

    changeButton(index, val) {
      if (val == 0) {
        this.electricList[index].dispOrder = 1;
      } else {
        this.electricList[index].dispOrder = 0;
      }
    },
    he() {
      this.windowOpean--;
    },
    kai() {
      this.windowOpean++;
    },

    changeSwitch(index, val) {
      console.log(111);
      //      if (val == "TurnOff") {
      //   val = "TurnOn";
      // } else {
      //   val = "TurnOff";
      // }
      return;
      // if (val == "TurnOff") {
      //   this.switchList[index].roomDeviceList.
      // } else {
      //   this.switchList[index].value = 0;
      // }
    },

    // 调频道
    toleft() {
      if (!this.chooseChannel) {
        this.chooseChannel = 0;
      }
      if (this.chooseChannel >= 0) {
        this.chooseChannel--;
      }
      if (this.chooseChannel < 0) {
        this.chooseChannel = this.TvChannelChoose.length - 1;
      }
      // return
      let channelNo = null;
      this.TvChannelChoose.forEach((item, index) => {
        if (index == this.chooseChannel) {
          channelNo = item.channelNo;
        }
      });
      // console.log(channelNo)
      // return;
      this.changeTvChannel(this.chooseChannel, channelNo);
    },
    toright() {
      if (!this.chooseChannel) {
        this.chooseChannel = 0;
      }
      if (this.chooseChannel < this.TvChannelChoose.length) {
        this.chooseChannel++;
      }
      if (this.chooseChannel == this.TvChannelChoose.length) {
        this.chooseChannel = 0;
      }
      let channelNo = null;
      this.TvChannelChoose.forEach((item, index) => {
        if (index == this.chooseChannel) {
          channelNo = item.channelNo;
        }
      });
      // return;
      this.changeTvChannel(this.chooseChannel, channelNo);
    },
    // 音量
    totop() {
      if (this.sy < 10) {
        this.sy++;
      }
      let val = "Add";
      this.changeVolume(val);
    },
    tobottom() {
      console.log(this.sy);
      if (this.sy > 0) {
        this.sy--;
      }
      let val = "Sub";
      this.changeVolume(val);
    },
    // 静音
    jinyin() {
      console.log(1);
      this.sy = 0;
      let val = "Mute";
      this.changeVolume(val);
    },
    switchChange(id, infor, index, index2, index3) {
      console.log(id, infor);
      let valueCode = null;
      let valueDesc = null;
      infor.values.forEach((item) => {
        if (item.valueCode == infor.realValue) {
          valueDesc = item.valueDesc;
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: id,
        paramCode: infor.paramCode,
        paramName: infor.paramName,
        valueCode: infor.realValue,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            // this.switchList[index].realValue = parms.valueCode;
            this.$message({
              duration: 5000,
              message: infor.paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            let status =
              this.switchList[index].roomDeviceList[index2]
                .roomDeviceStatusList[index3].realValue;
            console.log(status);
            // return;
            if (status == "TurnOn") {
              this.switchList[index].roomDeviceList[
                index2
              ].roomDeviceStatusList[index3].realValue = "TurnOff";
            } else {
              this.switchList[index].roomDeviceList[
                index2
              ].roomDeviceStatusList[index3].realValue = "TurnOn";
            }
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 窗帘开关
    clChange(item, index) {
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "Status") {
          paramName = item2.paramName;
          item2.values.forEach((item3) => {
            if (item3.valueCode == item.Status) {
              valueDesc = item3.valueDesc;
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "Status",
        paramName: paramName,
        valueCode: item.Status,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.CLmode.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "Status") {
                    items.Status = parms.valueCode;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            let status = this.CLmode[index].Status;
            if (status == "TurnOn") {
              this.CLmode[index].Status = "TurnOff";
            } else {
              this.CLmode[index].Status = "TurnOn";
            }
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },

    // 窗帘开合
    windowOpeanChange(item, index) {
      // this.getlist();
      console.log(this.electricList);

      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetValue") {
          paramName = item2.paramName;
          valueDesc = item2.values[0].valueDesc;
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetValue",
        paramName: paramName,
        valueCode: item.SetValue,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
            this.electricList.forEach((item) => {
              if (item.typeName == "窗帘") {
                console.log(item.roomDeviceList[index]);
                let changeList = item.roomDeviceList[index];
                console.log(333, changeList);
                changeList.roomDeviceStatusList.forEach((item2) => {
                  if (item2.paramCode == "SetValue") {
                    item2.realValue = Number(parms.valueCode);
                  }
                });
              }
            });
            console.log(4444444, this.electricList);
          } else {
            this.CLmode = this.electricList[this.electricIndex].roomDeviceList;
            this.CLmode.forEach((item) => {
              item.roomDeviceStatusList.forEach((item2) => {
                if (item2.paramCode == "SetValue") {
                  item.SetValue = Number(item2.realValue);
                }
              });
            });
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    //灯光设置开关
    cdSwitchChange(item, index) {
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "Status") {
          paramName = item2.paramName;
          item2.values.forEach((item3) => {
            if (item3.valueCode == item.Status) {
              valueDesc = item3.valueDesc;
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "Status",
        paramName: paramName,
        valueCode: item.Status,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.tgdList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "Status") {
                    items.Status = parms.valueCode;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            let status = this.tgdList[index].Status;
            if (status == "TurnOn") {
              this.tgdList[index].Status = "TurnOff";
            } else {
              this.tgdList[index].Status = "TurnOn";
            }
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 灯光设置亮度
    changeLight(item, index) {
      console.log(this.electricList);
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetBrightness") {
          paramName = item2.paramName;
          valueDesc = item2.values[0].valueDesc;
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetBrightness",
        paramName: paramName,
        valueCode: item.SetBrightness,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            // this.getlist();
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
            this.electricList.forEach((item) => {
              if (item.typeName == "调光灯") {
                console.log(item.roomDeviceList[index]);
                let changeList = item.roomDeviceList[index];
                console.log(333, changeList);
                changeList.roomDeviceStatusList.forEach((item2) => {
                  if (item2.paramCode == "SetBrightness") {
                    item2.realValue = Number(parms.valueCode);
                  }
                });
              }
            });
          } else {
            this.tgdList = this.electricList[this.electricIndex].roomDeviceList;
            this.tgdList.forEach((item) => {
              item.roomDeviceStatusList.forEach((item2) => {
                if (item2.paramCode == "SetBrightness") {
                  item.SetBrightness = Number(item2.realValue);
                }
              });
            });
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 双色温灯开关
    sswdSwitchChange(item, index) {
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "Status") {
          paramName = item2.paramName;
          item2.values.forEach((item3) => {
            if (item3.valueCode == item.Status) {
              valueDesc = item3.valueDesc;
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "Status",
        paramName: paramName,
        valueCode: item.Status,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.sswdList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "Status") {
                    items.Status = parms.valueCode;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            let status = this.sswdList[index].Status;
            if (status == "TurnOn") {
              this.sswdList[index].Status = "TurnOff";
            } else {
              this.sswdList[index].Status = "TurnOn";
            }
            // this.sswdList.forEach((items) => {
            //   if (items.id == item.id) {
            //     item.roomDeviceStatusList.forEach((item) => {
            //       if (item.paramCode == "Status") {
            //         items.Status = item.realValue;
            //       }
            //     });
            //   }
            // });
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 双色温灯亮度
    changesswd(item, index) {
      let SetBrightness = item.SetBrightness;
      // if (name == "reduce") {
      //   SetBrightness--;
      // } else {
      //   SetBrightness++;
      // }
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetBrightness") {
          paramName = item2.paramName;
          valueDesc = item2.values[0].valueDesc;
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetBrightness",
        paramName: paramName,
        valueCode: SetBrightness,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            // this.getlist();
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
            this.electricList.forEach((item) => {
              if (item.typeName == "双色温灯") {
                console.log(item.roomDeviceList[index]);
                let changeList = item.roomDeviceList[index];
                console.log(333, changeList);
                changeList.roomDeviceStatusList.forEach((item2) => {
                  if (item2.paramCode == "SetBrightness") {
                    item2.realValue = Number(parms.valueCode);
                  }
                });
              }
            });
          } else {
            this.sswdList =
              this.electricList[this.electricIndex].roomDeviceList;
            this.sswdList.forEach((item) => {
              item.roomDeviceStatusList.forEach((item2) => {
                if (item2.paramCode == "SetBrightness") {
                  item.SetBrightness = Number(item2.realValue);
                }
                if (item2.paramCode == "SetColorTemp") {
                  item.SetColorTemp = Number(item2.realValue);
                }
              });
            });
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    chaochu() {
      this.$message({
        duration: 5000,
        message: "超出范围0-100！",
        center: true,
        duration: 3000,
        type: "warning",
      });
    },
    // 空调超出
    ktchaochu() {
      this.$message({
        duration: 5000,
        message: "超出温度数值16-30！",
        center: true,
        duration: 3000,
        type: "warning",
      });
    },

    // 双色温灯色温
    changesswdsw(item, index) {
      // console.log(item.SetColorTemp, name);
      // if (name == "reduce") {
      //   if (item.SetColorTemp <= 2700) {
      //     this.$message({
      //       duration: 5000,
      //       message: "超出范围2700-7000！",
      //       center: true,
      //       duration: 3000,
      //       type: "warning",
      //     });
      //     return;
      //   }
      // } else {
      //   if (item.SetColorTemp >= 7000) {
      //     this.$message({
      //       duration: 5000,
      //       message: "超出范围2700-7000！",
      //       center: true,
      //       duration: 3000,
      //       type: "warning",
      //     });
      //     return;
      //   }
      // }
      let SetColorTemp = item.SetColorTemp;
      // if (name == "reduce") {
      //   SetColorTemp--;
      // } else {
      //   SetColorTemp++;
      // }
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetColorTemp") {
          paramName = item2.paramName;
          valueDesc = item2.values[0].valueDesc;
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetColorTemp",
        paramName: paramName,
        valueCode: SetColorTemp,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            // this.getlist();
            // this.sswdList.forEach((items, index) => {
            //   if (items.id == item.id) {
            //     this.sswdList[index].SetColorTemp = SetColorTemp;
            //   }
            // });
            // this.getlist();
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
            this.electricList.forEach((item) => {
              if (item.typeName == "双色温灯") {
                console.log(item.roomDeviceList[index]);
                let changeList = item.roomDeviceList[index];
                console.log(333, changeList);
                changeList.roomDeviceStatusList.forEach((item2) => {
                  if (item2.paramCode == "SetColorTemp") {
                    item2.realValue = Number(parms.valueCode);
                  }
                });
              }
            });
          } else {
            this.sswdList =
              this.electricList[this.electricIndex].roomDeviceList;
            this.sswdList.forEach((item) => {
              item.roomDeviceStatusList.forEach((item2) => {
                if (item2.paramCode == "SetColorTemp") {
                  item.SetColorTemp = Number(item2.realValue);
                }
                if (item2.paramCode == "SetBrightness") {
                  item.SetBrightness = Number(item2.realValue);
                }
              });
            });
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 彩灯开关
    cdChange(item, index) {
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "Status") {
          paramName = item2.paramName;
          item2.values.forEach((item3) => {
            if (item3.valueCode == item.Status) {
              valueDesc = item3.valueDesc;
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "Status",
        paramName: paramName,
        valueCode: item.Status,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.colorforList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "Status") {
                    items.Status = parms.valueCode;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            let status = this.colorforList[index].Status;
            if (status == "TurnOn") {
              this.colorforList[index].Status = "TurnOff";
            } else {
              this.colorforList[index].Status = "TurnOn";
            }
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 彩灯亮度
    cdLightChange(item, index) {
      let SetBrightness = item.SetBrightness;
      // if (name == "reduce") {
      //   SetBrightness--;
      // } else {
      //   SetBrightness++;
      // }
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetBrightness") {
          paramName = item2.paramName;
          valueDesc = item2.values[0].valueDesc;
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetBrightness",
        paramName: paramName,
        valueCode: SetBrightness,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            // this.colorforList.forEach((items, index) => {
            //   if (items.id == item.id) {
            //     this.colorforList[index].SetBrightness = SetBrightness;
            //   }
            // });
            // this.getlist();
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
            this.electricList.forEach((item) => {
              if (item.typeName == "彩灯") {
                console.log(item.roomDeviceList[index]);
                let changeList = item.roomDeviceList[index];
                console.log(333, changeList);
                changeList.roomDeviceStatusList.forEach((item2) => {
                  if (item2.paramCode == "SetBrightness") {
                    item2.realValue = Number(parms.valueCode);
                  }
                });
              }
            });
          } else {
            this.colorforList =
              this.electricList[this.electricIndex].roomDeviceList;
            this.colorforList.forEach((item) => {
              item.roomDeviceStatusList.forEach((item2) => {
                if (item2.paramCode == "SetBrightness") {
                  item.SetBrightness = Number(item2.realValue);
                }
              });
            });
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    }, // 彩灯选择颜色
    sendColor(item) {
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetColor") {
          paramName = item2.paramName;
          valueDesc = item2.values[0].valueDesc;
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetColor",
        paramName: paramName,
        valueCode: item.SetColor,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.colorforList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "SetColor") {
                    items.SetColor = parms.valueCode;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            this.colorforList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "SetColor") {
                    items.SetColor = item.realValue;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    //空调状态
    ktSwitchChange(item, index) {
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "Status") {
          paramName = item2.paramName;
          item2.values.forEach((item3) => {
            if (item3.valueCode == item.Status) {
              valueDesc = item3.valueDesc;
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "Status",
        paramName: paramName,
        valueCode: item.Status,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.ktList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "Status") {
                    items.Status = parms.valueCode;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            // this.ktList.forEach((items) => {
            //   if (items.id == item.id) {
            //     item.roomDeviceStatusList.forEach((item) => {
            //       if (item.paramCode == "Status") {
            //         items.Status = item.realValue;
            //       }
            //     });
            //   }
            // });
            let status = this.ktList[index].Status;
            if (status == "TurnOn") {
              this.ktList[index].Status = "TurnOff";
            } else {
              this.ktList[index].Status = "TurnOn";
            }
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    ktChange() {
      let paramName = null;
      let valueDesc = null;
      let valueCode = null;
      let id = null;
      let status = null;
      this.EquInfor.roomDeviceList.forEach((item) => {
        if (item.deviceName == "空调") {
          id = item.id;
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "Status") {
              paramName = item2.paramName;
              if (item2.realValue == "TurnOn") {
                status = true;
              } else {
                status = false;
              }
              item2.values.forEach((item3) => {
                if (this.ktStatus == true) {
                  valueCode = "TurnOn";
                } else {
                  valueCode = "TurnOff";
                }
                if (item3.valueCode == valueCode) {
                  valueDesc = item3.valueDesc;
                }
              });
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: id,
        paramCode: "Status",
        paramName: paramName,
        valueCode: valueCode,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
            this.ktStatus = parms.valueCode;
          });
          if (res.status == 200) {
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            this.ktStatus = status;
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 空调温度
    changeKtwd(item, name) {
      let SetTemp = item.SetTemp;
      if (name == "reduce") {
        SetTemp--;
      } else {
        SetTemp++;
      }
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetTemp") {
          paramName = item2.paramName;
          valueDesc = item2.values[0].valueDesc;
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetTemp",
        paramName: paramName,
        valueCode: SetTemp,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            this.ktList.forEach((items, index) => {
              console.log(333, items, item);
              if (items.id == item.id) {
                this.ktList[index].SetTemp = SetTemp;
              }
            });
            // this.getlist();
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 空调风速
    SetFanChange(item, val, index) {
      console.log(item, val);
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetFan") {
          paramName = item2.paramName;
          item2.values.forEach((item3) => {
            if (item3.valueCode == item.SetFan) {
              valueDesc = item3.valueDesc;
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetFan",
        paramName: paramName,
        valueCode: val,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.ktList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "SetFan") {
                    items.SetFan = parms.valueCode;
                    item.realValue = parms.valueCode;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            this.ktList.forEach((item) => {
              item.roomDeviceStatusList.forEach((item2) => {
                if (item2.paramCode == "SetFan") {
                  item.SetFan = item2.realValue;
                }
              });
            });

            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 空调模式
    SetModeChange(item, val) {
      console.log(item, val);
      let paramName = null;
      let valueDesc = null;
      item.roomDeviceStatusList.forEach((item2) => {
        if (item2.paramCode == "SetMode") {
          paramName = item2.paramName;
          item2.values.forEach((item3) => {
            if (item3.valueCode == item.SetMode) {
              valueDesc = item3.valueDesc;
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: item.id,
        paramCode: "SetMode",
        paramName: paramName,
        valueCode: val,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        console.log(333, this.ktList);
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.ktList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "SetMode") {
                    items.SetMode = parms.valueCode;
                    item.realValue = parms.valueCode;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            this.ktList.forEach((items) => {
              if (items.id == item.id) {
                item.roomDeviceStatusList.forEach((item) => {
                  if (item.paramCode == "SetMode") {
                    items.SetMode = item.realValue;
                  }
                });
              }
            });
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    getFounderList() {
      let parms = {
        limit: 10000,
        offset: 1,
      };
      postRequest("selectAllHotelTvChannel", parms).then((res) => {
        if (res.status == 200) {
          this.TvChannelChoose = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    turnOnLock() {
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: this.mensuoId,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("turnOnLock", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.$message({
              duration: 5000,
              message: "开锁成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },

    // 电视开关
    tvSwitch() {
      let paramName = null;
      let valueDesc = null;
      let valueCode = null;
      let id = null;
      let status = null;
      this.EquInfor.roomDeviceList.forEach((item) => {
        if (item.deviceName == "电视") {
          id = item.id;
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "Status") {
              paramName = item2.paramName;
              if (item2.realValue == "TurnOn") {
                status = true;
              } else {
                status = false;
              }
              item2.values.forEach((item3) => {
                if (this.tvStatus == true) {
                  valueCode = "TurnOn";
                } else {
                  valueCode = "TurnOff";
                }
                if (item3.valueCode == valueCode) {
                  valueDesc = item3.valueDesc;
                }
              });
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: id,
        paramCode: "Status",
        paramName: paramName,
        valueCode: this.tvStatus,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            this.tvStatus = parms.valueCode;
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            this.tvStatus = status;
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 电视频道
    changeTvChannel(index, val) {
      this.chooseChannel = index;
      // console.log(val);
      // return;
      let paramName = null;
      let valueDesc = null;
      let id = null;
      let realValue = null;
      this.EquInfor.roomDeviceList.forEach((item) => {
        if (item.deviceName == "电视") {
          id = item.id;
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "SetChannel") {
              realValue = item2.realValue;
              paramName = item2.paramName;
              valueDesc = item2.values[0].valueDesc;
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: id,
        paramCode: "SetChannel",
        paramName: paramName,
        valueCode: val,
        valueDesc: valueDesc,
      };
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.returnChannel = parms.valueCode;
            this.chooseChannel = parms.valueCode;
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            // console.log(this.returnChannel);
            this.chooseChannel = this.returnChannel;
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
              duration: 3000,
            });
          }
          this.flag = 0;
        });
      }
    },
    // 电视声音
    changeVolume(val) {
      console.log(val);
      let paramName = null;
      let valueDesc = null;
      let id = null;
      let realValue = null;
      this.EquInfor.roomDeviceList.forEach((item) => {
        if (item.deviceName == "电视") {
          id = item.id;
          item.roomDeviceStatusList.forEach((item2) => {
            if (item2.paramCode == "SetVolume") {
              realValue = item2.realValue;
              paramName = item2.paramName;
              item2.values.forEach((item3) => {
                if (item3.valueCode == val) {
                  valueDesc = item3.valueDesc;
                }
              });
            }
          });
        }
      });
      let parms = {
        roomId: Number(this.infor.id),
        deviceId: id,
        paramCode: "SetVolume",
        paramName: paramName,
        valueCode: val,
        valueDesc: valueDesc,
      };
      console.log(parms);
      if (this.flag == 0) {
        this.flag = 1;
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("controlRoomDevice", parms).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
            // this.getlist();
          });
          if (res.status == 200) {
            this.$message({
              duration: 5000,
              message: paramName + "设置成功！",
              center: true,
              duration: 3000,
              type: "success",
            });
          } else {
            (this.sy = 5),
              this.$message({
                duration: 5000,
                message: res.message,
                center: true,
                duration: 3000,
              });
          }
          this.flag = 0;
        });
      }
    },
    getInfor(name) {
      let id = null;
      this.EquInfor.roomDeviceList.forEach((item) => {
        if (item.deviceName == name) {
          id = item.id;
          return id;
        }
        // item.roomDeviceStatusList.forEach((item2) => {
        //   // 1.风速
        //   if (item2.paramCode == "SetFan") {
        //     console.log(item2);
        //   }
        // });
      });
    },
    pdchaochu() {},
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.outer {
  height: 100%;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hl-header {
  width: 100%;
  height: 8%;
  display: flex;
}

.hl-content {
  flex: 1;
  background-color: #f5f6fa;
  height: 100%;
  /* padding: 21px 32px; */
  position: relative;
  /* overflow: scroll; */
}
.container {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
  width: 100%;
}
.left {
  /* background-color: antiquewhite; */
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right {
  background: #fff;
  width: 23%;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
}
.left-top {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
}
.left-bottom {
  width: 100%;
  height: 76%;
  border-radius: 12px;
  background-color: #ffffff;
  padding: 24px;
  overflow: scroll;
}
.electric {
  width: 23%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  opacity: 1;
  border-radius: 12px;
  padding: 14px;
  position: relative;
  margin-right: 10px;
}
.electricTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.electricName {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #000000;
  opacity: 0.85;
  position: absolute;
  bottom: 32%;
}
.Opened {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #242424;
  opacity: 0.45;
  position: absolute;
  bottom: 15%;
}
.chooseElect {
  background: #1890ff;
}
.chooseItem {
  color: #fff;
}
.chooseItem1 {
  color: #ffffff;
  opacity: 0.65;
}
.picture {
  display: inline-block;
  height: 32px;
  width: 32px;
}
.switch {
  width: 100%;
  height: 8%;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  opacity: 1;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 16px;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 10px;
}
.light {
  width: 100%;
  height: 100%;
}
.light-top {
  display: flex;
  justify-content: space-between;
  z-index: 99999;
}
.dgsz {
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 150px;
}
.lightSet {
  margin-left: 16px;
}
.twoColor {
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f7f7f7;
  opacity: 1;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  z-index: 1 !important;
}
.ONOff {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
}
.ON {
  margin-right: 12px;
}
.light-center {
  margin-top: 7%;
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  align-items: center;
  z-index: -1;
}
.tv-center {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 8%;
  /* height: 75%; */
  /* align-items: center; */
}
.tempe1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 106px 0; */
  /* background: #1890ff; */
}
.tempe1 > div {
  position: absolute;
  /* background: #0016ff; */
  height: 70px;
  width: 80px;
}
.toleft {
  left: 0;
}
.toright {
  right: 0;
}
.totop {
  top: 0;
}
.tobottom {
  bottom: 0;
}
.tempe {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 386px;
  /* background: #1890ff; */
}
.radius {
  position: absolute;
  margin-top: 45px;
}
.liangdu {
  position: absolute;
  /* height: 100%; */
}
.temperature {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tempeNum {
  font-size: 36px;
  font-weight: 400;
  line-height: 54px;
  color: #000000;
  opacity: 0.85;
}
.wd {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.45;
}
.light-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3% 0;
}
.slider {
  width: 200px;
}
.window {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.window > span:first-child {
  margin-right: 20px;
}
.color {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chooseColor {
  /* margin-bottom: 20px; */
  margin-right: 20px;
}
.colorBox {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.colorOutBox {
  display: flex;
  justify-content: space-between;
}
.color1 {
  background: #ff9500;
}
.color2 {
  background: #ffe620;
}
.color3 {
  background: #09ff00;
}
.color4 {
  background: #50e3c2;
}
.color5 {
  background: #1890ff;
}
.color6 {
  background: #0016ff;
}
.color7 {
  background: #9a3fea;
}
.color8 {
  background: #ff3ac1;
}
.color9 {
  background: #ff0f0f;
}
>>> .el-select__caret {
  display: none !important;
}
.el-select-dropdown__list > li {
  padding-left: 11px;
}
>>> .el-input__inner {
  width: 190px;
  height: 40px;
  line-height: 40px;
  /* text-align: center; */
  background: #f7f7f7;
  opacity: 1;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  /* color: rgba(0, 0, 0, 0.85); */
  outline: none !important;
  border: none !important;
}
>>> input:focus {
  border: none !important;
}
.jinyinon {
  margin-right: 10px;
}
.beChooseColor {
  border: 3px solid #4080bc;
}
.waterAndElect {
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  justify-content: space-between;
}
.waterAndElect > div {
  width: 48%;
  height: 96px;
  border-radius: 8px 8px 8px 8px;
  padding: 12px 16px;
}
.water {
  border: 1px solid #cff5e2;
  position: relative;
}
.elect {
  border: 1px solid #fdc0c3;
  position: relative;
}
.waterInfor {
  display: flex;
  justify-content: space-between;
}
.electInfor {
  display: flex;
  justify-content: space-between;
}
.shuibiao {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  opacity: 0.45;
}
.shuibiaoNumber {
  font-size: 22px;
  font-weight: 500;
  color: #000000;
  line-height: 32px;
}
.waterBottom {
  height: 26px;
  line-height: 26px;
  display: flex;
  align-items: center;
  background: #cff5e2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #00c965;
  font-size: 12px;
}
.waterRidus {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background: #00c965;
  opacity: 1;
  border: 2px solid rgba(255, 255, 255, 0.95);
  margin: 0 5px 0 16px;
}
.electRidus {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background: #ff1414;
  opacity: 1;
  border: 2px solid rgba(255, 255, 255, 0.95);
  margin: 0 5px 0 16px;
}
.electBottom {
  height: 26px;
  line-height: 26px;
  display: flex;
  align-items: center;
  background: #fdc0c3;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #ff1414;
  font-size: 12px;
}
.shengyin {
  position: relative;
  width: 340px;
  height: 40px;
}
.yinliang {
  position: absolute;
}

.showsy {
  z-index: 9999;
  position: absolute;
  overflow: hidden;
}
.sy {
  width: 0%;
}
.sy1 {
  width: 10%;
}
.sy2 {
  width: 20%;
}
.sy3 {
  width: 30%;
}
.sy4 {
  width: 40%;
}
.sy5 {
  width: 50%;
}
.sy6 {
  width: 60%;
}
.sy7 {
  width: 70%;
}
.sy8 {
  width: 80%;
}
.sy9 {
  width: 90%;
}
.sy10 {
  width: 100%;
}
.khd {
  margin-right: 18px;
}
.back {
  padding: 24px 0 0 24px;
}
>>> .el-breadcrumb__inner {
  cursor: pointer !important;
  /* font-weight: bold !important; */
  font-size: 20px !important;
  color: #000000 !important;
}
.theColor {
  width: 150px;
}
>>> .m-colorPicker .colorBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.m-colorPicker .box {
  position: absolute;
  bottom: 20px;
}
.mensuo {
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  width: 33%;
  /* font-weight: bold; */
}
.yuan1 {
  width: 8px !important;
  height: 8px;
  background: #ff1414;
  border-radius: 50%;
  margin-right: 10px;
}
.yuan2 {
  width: 8px;
  height: 8px;
  background: #1890ff;
  border-radius: 50%;
  margin-right: 10px;
}
.yuan3 {
  width: 8px;
  height: 8px;
  background: #00c965;
  border-radius: 50%;
  margin-right: 10px;
}
.mensuoInfor > div:nth-child(1) {
  border-right: 1px solid #d1d1d1;
}
.mensuoInfor > div:nth-child(2) {
  border-right: 1px solid #d1d1d1;
}
/* .mensuo > div:nth-child(2) {
  width: 140px;
  text-align: end;
  margin-right: 10px;
  font-size: 18px;
} */
.res {
  /* font-weight: bold; */
}
.msTop {
  display: flex;
  justify-content: space-between;
}
.msCenter {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.mensuoInfor {
  margin-top: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  width: 80%;
  height: 64px;
  border-radius: 12px;
}
.mensuoOut {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dgBox {
  width: 100%;
  height: 150px;
  border: 1px solid #f7f7f7;
  padding: 22px;
  margin-top: 20px;
  border-radius: 16px;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
}
.dgBoxCl {
  width: 100%;
  height: 150px;
  border: 1px solid #f7f7f7;
  padding: 17px;
  margin-top: 20px;
  border-radius: 16px;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
}
.showList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#value {
  color: #7e7d7d;
  margin-left: 10px;
}
#value1 {
  color: #7e7d7d;
  margin-left: 10px;
}
#value2 {
  color: #7e7d7d;
  margin-left: 10px;
}
#value3 {
  color: #7e7d7d;
  margin-left: 10px;
}
#value4 {
  color: #7e7d7d;
  margin-left: 10px;
}
.showList > img {
  height: 35px;
  width: 35px;
}
.dgBox1 {
  width: 100%;
  height: 217px;
  border: 1px solid #f7f7f7;
  padding: 40px;
  margin-top: 20px;
  border-radius: 16px;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
}
.dgBox2 {
  width: 100%;
  height: 267px;
  border: 1px solid #f7f7f7;
  padding: 16px;
  margin-top: 20px;
  border-radius: 16px;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
}
.dengGuang {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ld {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.ld1 {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.dengGuangInfor {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.img {
  height: 80px;
  width: 80px;
}
.name {
  margin-bottom: 10px;
}
.centerBox {
  display: flex;
  flex-wrap: wrap;
  /* overflow: scroll; */
  /* justify-content: space-between; */
}
.centerBox > div {
  margin: 0 2%;
}
.el-radio-group {
  width: 100%;
}
.tvChoose {
  display: flex;
  justify-content: center;
}
.tvChoosebox {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.jiudian {
  background: #1890ff !important;
  color: #fff;
}
.tvChoosebox > div {
  width: 21%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 20px;
  margin: 5px 2%;
  background-color: #f7f7f7;
}
>>> .sbkz1 {
  font-weight: bold !important;
  font-size: 20px;
}
</style>